import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  TableFooter
} from '@material-ui/core';
import theme from '../../../../theme';
import MaterialTablePagination from '../../../atoms/materialTablePagination';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
import Currency from '../../../molecules/Currency';
import { isEmpty } from 'lodash';
import { PlatformImage } from './styles';
import { VARIANT_COMBO_DELIMITER } from '../../../../constants';
import { sortAlphaNumeric } from '../../../../utils';

const { INVOLVED, NOT_INVOLVED } = theme.default;

const styles = theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  tableBody: {
    opacity: '1',
    transition: 'all .1s linear'
  },
  empty: {
    height: '380px'
  },
  tableBodyDisabled: {
    opacity: '0.4',
    pointerEvents: 'none'
  },
  imageCell: {
    display: 'flex',
    alignItems: 'center'
  },
  adjustmentsRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  adjustmentsData: {
    margin: '0 8px'
  }
});

const lightBorder = '1px solid #ebebeb';
const dontIncludeLevels = ['lMarket', 'networkCommission', 'shoptypeCharge'];
let lowestLevel = '';

class ordersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrder: -1,
      openProduct: -1,
      rows: []
    };
  }

  componentDidMount() {
    if (this.props.orderDetails.length > 0) {
      this.restructureOrderDetails();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.orderDetails.length <= 0 &&
        this.props.orderDetails.length > 0) ||
      (prevProps.fetchingOrders && !this.props.fetchingOrders)
    ) {
      this.restructureOrderDetails();
    }
  }

  restructureOrderDetails = () => {
    const { orderDetails } = this.props;
    this.setState({ rows: orderDetails });
  };

  reIterateLevelName = level => {
    switch (level) {
      case 'lIntro':
        return 'Introduction Click';

      case 'lX':
        return 'Close Click';

      default:
        // Extract the numeric part from the commission string
        const levelNumber = parseInt(level.match(/\d+/)[0]);

        // Extract the numeric part from the lowest level
        const lowestLevelNumber = parseInt(lowestLevel.match(/\d+/)[0]);

        // Subtract the lowestLevelNumber from the current level number
        // to get the correct click number
        const clickNumber = levelNumber - lowestLevelNumber + 1;
        return `Click ${clickNumber}`;
    }
  };

  arrangeData = arr => {
    const sortedArray = sortAlphaNumeric(arr, 'ASC');
    // Need lowestLevel for Click calculation ( reIterateLevelName() )
    lowestLevel = sortedArray[0];
    return sortedArray;
  };

  adjustmentName = shortName => {
    switch (shortName) {
      case 'lShare':
        return 'Share Earnings';

      case 'lInvite':
        return 'Invite Earnings';

      case 'lReferrer':
        return 'Referrer Earnings';

      case 'lPartner':
        return 'Partner Earnings';

      case 'cosellerSplit':
        return 'Coseller Split';

      default:
        return shortName;
    }
  };

  openOrder = index => {
    this.setState({ openProduct: -1, openOrder: index });
  };

  closeOrder = () => {
    this.setState({ openOrder: -1 });
  };

  openProduct = productIndex => {
    this.setState({ openProduct: productIndex });
  };

  closeProduct = () => {
    this.setState({ openProduct: -1 });
  };

  render() {
    const { openOrder, openProduct, rows } = this.state;
    const {
      classes,
      orderDetails,
      totalOrdersCount,
      currentPage,
      fetchingOrders,
      handleChangePage
    } = this.props;
    return (
      <>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Order No.</TableCell>
                <TableCell>No. of items</TableCell>
                <TableCell align="left">Date&nbsp;&amp;&nbsp;Time</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Earning</TableCell>
                <TableCell>Network</TableCell>
                <TableCell>Platform</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody
              className={`${classes.tableBody} ${fetchingOrders ? classes.tableBodyDisabled : null
                }`}
            >
              <>
                {rows.map((row, indexA) => (
                  <React.Fragment key={indexA}>
                    <TableRow className={classes.root}>
                      <TableCell align="left" component="th" scope="row">
                        {row.externalId || '—'}
                      </TableCell>
                      <TableCell align="left">{row.quantity || '—'}</TableCell>
                      <TableCell align="left">
                        {row.createdAt
                          ? moment(row.createdAt).format(
                            'DD[/]MMMM[/]YYYY h:mm A'
                          )
                          : '—'}
                      </TableCell>
                      <TableCell align="left">
                        <Currency currencyName={row.price.currency || ''} />
                        {row.price.amount || '—'}
                      </TableCell>
                      <TableCell align="left">
                        <Currency
                          currencyName={row.commissionTotal.currency || ''}
                        />
                        {row.commissionTotal.amount || '—'}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title="Introduction Payout">
                          <FiberManualRecordIcon
                            fontSize="medium"
                            htmlColor={
                              !isEmpty(row.attributions) &&
                                row.attributions.lIntro
                                ? INVOLVED
                                : NOT_INVOLVED
                            }
                          />
                        </Tooltip>
                        <Tooltip title="Middle Stages">
                          <FiberManualRecordIcon
                            fontSize="medium"
                            htmlColor={
                              !isEmpty(row.attributions) &&
                                row.attributions.lMiddle
                                ? INVOLVED
                                : NOT_INVOLVED
                            }
                          />
                        </Tooltip>
                        <Tooltip title="Close">
                          <FiberManualRecordIcon
                            fontSize="medium"
                            htmlColor={
                              !isEmpty(row.attributions) && row.attributions.lX
                                ? INVOLVED
                                : NOT_INVOLVED
                            }
                          />
                        </Tooltip>
                        &emsp;&emsp;&emsp;&emsp;&emsp;
                        <Tooltip title="Invite Earning">
                          <FiberManualRecordIcon
                            fontSize="medium"
                            htmlColor={
                              !isEmpty(row.attributions) &&
                                row.attributions.lInvite
                                ? INVOLVED
                                : NOT_INVOLVED
                            }
                          />
                        </Tooltip>
                        <Tooltip title="Coseller Earning">
                          <FiberManualRecordIcon
                            fontSize="medium"
                            htmlColor={
                              !isEmpty(row.attributions) &&
                                row.attributions.lReferrer
                                ? INVOLVED
                                : NOT_INVOLVED
                            }
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title={row.platformUrl || 'shoptype.com'}>
                          <PlatformImage
                            src={`https://www.google.com/s2/favicons?sz=64&domain_url=${row.platformUrl ||
                              'shoptype.com'}`}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            openOrder === indexA
                              ? this.closeOrder()
                              : this.openOrder(indexA)
                          }
                        >
                          {openOrder === indexA ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={8}
                      >
                        <Collapse
                          style={{ backgroundColor: '#F5F5F5' }}
                          in={openOrder === indexA}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <Table size="small" aria-label="products">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Product</TableCell>
                                  <TableCell align="left">Quantity</TableCell>
                                  <TableCell align="left">Variant</TableCell>
                                  <TableCell align="left">Price</TableCell>
                                  <TableCell />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.productDetails.map((product, indexB) => (
                                  <React.Fragment key={indexB}>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          border: 'none'
                                        }}
                                        align="left"
                                        component="th"
                                        scope="row"
                                      >
                                        <div className={classes.imageCell}>
                                          <div>
                                            <img
                                              src={
                                                product.primaryImageSrc.imageSrc
                                              }
                                              width="50px"
                                              height="50px"
                                              alt="product media"
                                            />
                                          </div>
                                          <div style={{ marginLeft: 4 }}>
                                            {product.title || '—'}
                                          </div>
                                        </div>
                                      </TableCell>
                                      <TableCell align="left">
                                        {product.quantity || '—'}
                                      </TableCell>
                                      <TableCell align="left">
                                        {(!isEmpty(product.variantNameValue) &&
                                          Object.values(
                                            product.variantNameValue
                                          ).join(VARIANT_COMBO_DELIMITER)) ||
                                          '—'}
                                      </TableCell>
                                      <TableCell align="left">
                                        <Currency
                                          currencyName={
                                            product.price.currency || ''
                                          }
                                        />
                                        {product.price.amount || '—'}
                                      </TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                          aria-label="expand row"
                                          size="small"
                                          onClick={() =>
                                            openProduct === indexB
                                              ? this.closeProduct()
                                              : this.openProduct(indexB)
                                          }
                                        >
                                          {openProduct === indexB ? (
                                            <KeyboardArrowUpIcon />
                                          ) : (
                                            <KeyboardArrowDownIcon />
                                          )}
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          paddingBottom: 0,
                                          paddingTop: 0
                                        }}
                                        colSpan={8}
                                      >
                                        <Collapse
                                          style={{ backgroundColor: '#E5E5E5' }}
                                          in={openProduct === indexB}
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <Box margin={1}>
                                            <Table
                                              size="small"
                                              aria-label="products"
                                            >
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell
                                                    align="left"
                                                    style={{
                                                      borderBottom: lightBorder
                                                    }}
                                                  >
                                                    Stage
                                                  </TableCell>
                                                  <TableCell
                                                    align="left"
                                                    style={{
                                                      borderBottom: lightBorder
                                                    }}
                                                  >
                                                    Coseller
                                                  </TableCell>
                                                  <TableCell
                                                    align="left"
                                                    style={{
                                                      borderBottom: lightBorder
                                                    }}
                                                  >
                                                    Earning
                                                  </TableCell>
                                                  <TableCell
                                                    align="left"
                                                    style={{
                                                      borderBottom: lightBorder
                                                    }}
                                                  >
                                                    Date&nbsp;&amp;&nbsp;Time
                                                  </TableCell>
                                                  <TableCell
                                                    align="left"
                                                    style={{
                                                      borderBottom: lightBorder
                                                    }}
                                                  >
                                                    Platform
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {!isEmpty(
                                                  product.coseller_commission
                                                ) ? (
                                                  this.arrangeData(
                                                    Object.keys(
                                                      product.coseller_commission
                                                    )
                                                  )
                                                    .filter(
                                                      item =>
                                                        !dontIncludeLevels.includes(
                                                          item
                                                        )
                                                    )
                                                    .map(
                                                      (commission, indexC) => (
                                                        <TableRow key={indexC}>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="left"
                                                            style={{
                                                              borderBottom: lightBorder
                                                            }}
                                                          >
                                                            {this.reIterateLevelName(
                                                              commission
                                                            )}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            style={{
                                                              borderBottom: lightBorder
                                                            }}
                                                          >
                                                            {product
                                                              .coseller_commission[
                                                              commission
                                                            ].userName || '—'}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            style={{
                                                              borderBottom: lightBorder
                                                            }}
                                                          >
                                                            <Currency
                                                              currencyName={
                                                                product
                                                                  ?.coseller_commission[
                                                                  commission
                                                                ]?.amount
                                                                  ?.currency ||
                                                                ''
                                                              }
                                                            />
                                                            {product
                                                              ?.coseller_commission[
                                                              commission
                                                            ]?.amount?.amount ||
                                                              '—'}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            style={{
                                                              borderBottom: lightBorder
                                                            }}
                                                          >
                                                            {product
                                                              ?.coseller_commission[
                                                              commission
                                                            ]?.createdAt
                                                              ? moment(
                                                                product
                                                                  .coseller_commission[
                                                                  commission
                                                                ].createdAt
                                                              ).format(
                                                                'DD[/]MMMM[/]YYYY h:mm A'
                                                              )
                                                              : '—'}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            style={{
                                                              borderBottom: lightBorder
                                                            }}
                                                          >
                                                            <Tooltip
                                                              title={
                                                                product
                                                                  ?.coseller_commission[
                                                                  commission
                                                                ]
                                                                  ?.platformUrl ||
                                                                'shoptype.com'
                                                              }
                                                            >
                                                              <PlatformImage
                                                                src={`https://www.google.com/s2/favicons?sz=64&domain_url=${product
                                                                  ?.coseller_commission[
                                                                  commission
                                                                ]
                                                                  ?.platformUrl ||
                                                                  'shoptype.com'}`}
                                                              />
                                                            </Tooltip>
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )
                                                ) : (
                                                  <TableRow>
                                                    <TableCell
                                                      align="center"
                                                      colSpan={8}
                                                    >
                                                      Earnings details
                                                      unavailable
                                                    </TableCell>
                                                  </TableRow>
                                                )}
                                                {!isEmpty(
                                                  product.coseller_adjustment
                                                ) ? (
                                                  <TableRow>
                                                    <TableCell
                                                      component="th"
                                                      scope="row"
                                                      align="left"
                                                      colSpan={8}
                                                      style={{
                                                        borderBottom: lightBorder
                                                      }}
                                                    >
                                                      <div
                                                        className={
                                                          classes.adjustmentsRow
                                                        }
                                                      >
                                                        {Object.keys(
                                                          product.coseller_adjustment
                                                        ).map(
                                                          (
                                                            adjustment,
                                                            indexD
                                                          ) => (
                                                            <div
                                                              key={indexD}
                                                              className={
                                                                classes.adjustmentsData
                                                              }
                                                            >
                                                              {`${this.adjustmentName(
                                                                adjustment
                                                              )} : `}
                                                              <Currency
                                                                currencyName={
                                                                  product
                                                                    ?.coseller_adjustment[
                                                                    adjustment
                                                                  ]?.currency ||
                                                                  ''
                                                                }
                                                              />
                                                              {`${product?.coseller_adjustment[adjustment]?.amount}`}
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </TableCell>
                                                  </TableRow>
                                                ) : (
                                                  <TableRow>
                                                    <TableCell
                                                      align="center"
                                                      colSpan={8}
                                                    >
                                                      Adjustment details
                                                      unavailable
                                                    </TableCell>
                                                  </TableRow>
                                                )}
                                              </TableBody>
                                            </Table>
                                          </Box>
                                        </Collapse>
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </>
            </TableBody>
            <TableFooter>
              <TableRow>
                <MaterialTablePagination
                  colSpan={8}
                  count={totalOrdersCount}
                  page={currentPage}
                  handleChangePage={handleChangePage}
                  rowsPerPage={6}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default withStyles(styles)(ordersTable);
